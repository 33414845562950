import React from 'react';
import '../style/index.css';
import { Link } from 'react-router-dom';

function CGV() {
  return (
    <div className='conditions'>
        <div>
            <button className='buttonAccueil'><Link to="/"><p>Retour à la page d'accueil</p></Link></button>
        </div>
      <h1>Conditions Générales de Vente</h1>
      <p>Les présentes conditions générales de vente s'appliquent aux prestations fournies par l'établissement : toute réservation implique de la part du client l’acceptation des présentes conditions. Seules les clauses particulières des accords signés avec le client pourront compléter, préciser, voire déroger aux présentes conditions de vente.</p>
      
      <h2>Tarifs</h2>
      <p>Les tarifs sont exprimés en euros TTC. Ils s’entendent par personne pour les prestations de repas.</p>
      <p>Pour la privatisation d’un salon ou des prestations annexes, les tarifs sont forfaitaires.</p>
      <p>Les tarifs applicables sont ceux en vigueur au moment de la réservation. Si toutefois la réservation a eu lieu plus de dix mois avant la date de la prestation, Le Louisabella se réserve le droit d’appliquer ses tarifs en vigueur au moment de la prestation, moyennant un préavis prévu dans la proposition commerciale.</p>
      <p>Il appartient au client d'apprécier lors de la réservation si le prix lui convient en acceptant son caractère forfaitaire. Aucune contestation concernant le prix de la prestation ne pourra être prise en considération ultérieurement.</p>

      <h2>Confirmation de réservation</h2>
      <p>Toute réservation n’est effective qu’après confirmation du Louisabella par mail ou téléphone.</p>

      <h2>Confirmation du menu</h2>
      <p>La proposition commerciale ou de devis peut comporter un choix entre plusieurs menus.</p>
      <p>Afin de pouvoir pleinement satisfaire le client, celui-ci doit indiquer son choix définitif au restaurant au plus tard six jours avant la prestation.</p>
      <p>Après ce délai, le restaurant ne pourra garantir le service de certains mets ou vins composant le menu.</p>

      <h2>Annulation partielle du nombre de convives</h2>
      <p>Le client doit confirmer le nombre définitif de convives au plus tard 3 jours avant le déroulement de la prestation.</p>
      <h3>Pour une modification du nombre de convives jusqu’à 3 jours avant la date de la prestation :</h3>
      <ul>
        <li><p>Si le nombre de convives annulé représente moins de 10% du nombre total de convives, le client sera facturé sur la base du nombre de convives confirmés ;</p></li>
        <li><p>Au-delà de 10% du nombre de participants annulés, le client sera facturé 70% du prix unitaire du devis initial multiplié par le nombre de participants annulés au titre de frais de résiliation.</p></li>
      </ul>
      <p>Pour toute modification 3 jours ou moins avant la date de la prestation, quel que soit le nombre de participants annulé ou non présents le jour de la prestation, les frais de résiliation porteront sur 100% du prix unitaire figurant dans le devis initial, multiplié par le nombre de participants annulés.</p>

      <h2>Nombre de convives supérieur à celui prévu lors de la réservation</h2>
      <p>Si le nombre de convives est supérieur au nombre annoncé, il sera facturé un supplément sur la base unitaire indiquée dans la proposition commerciale ou le devis initial.</p>
      <h3>Toutefois, en cas d’adjonction trop importante de participants supplémentaires :</h3>
      <ul>
        <li><p>Le Louisabella ne saurait être tenu responsable de l’impact éventuel sur la qualité de la prestation ;</p></li>
        <li><p>Si cette adjonction impliquait un dépassement de la capacité d’accueil du restaurant, Le Louisabella serait dans l’obligation de refuser l’accueil des participants supplémentaires et ne pourrait en aucun cas être tenu responsable et mis en cause en raison du refus d’accueil pour raisons de sécurité de participants supplémentaires.</p></li>
      </ul>

      <h2>Annulation par Le Louisabella</h2>
      <p>Toute annulation par Le Louisabella, et sauf cas de force majeure, entraîne le remboursement des sommes versées par le client au titre de l’acompte, à l’exclusion de toute autre indemnité.</p>
      <p>Le restaurant Le Louisabella pourrait se dégager de ses obligations ou en suspendre l’exécution en cas de tout événement indépendant de sa volonté faisant obstacle à son fonctionnement normal tels que grèves totales ou partielles de fournisseurs, sous-traitants ou transporteurs, interruption des transports, de la fourniture d’énergie, de matières premières ou en cas de force majeure ( guerre, émeutes, manifestations, sinistres affectant les installations du restaurant…).</p>

      <h2>Déroulement de la prestation</h2>
      <p>Le jour de la prestation, les convives doivent se présenter au restaurant à l'heure convenue. En cas de retard, le restaurant se réserve le droit de faire attendre les convives. Si ce retard est supérieur à une heure, la réservation ne sera plus garantie.</p>
      <p>Aucune réclamation sur le déroulement de la prestation ne sera recevable postérieurement au jour de celle-ci.</p>

      <h2>Prestations supplémentaires non incluses dans la proposition commerciale ou le devis</h2>
      <p>Toute prestation supplémentaire non incluse lors de la réservation – boissons, plats – fera l’objet d’une facturation complémentaire le jour même de la prestation.</p>

      <h2>Responsabilité du client</h2>
      <p>Le client est réputé avoir souscrit une assurance civile pour les dommages éventuellement causés par ses convives lors du déroulement de la prestation, que ce soit des dommages infligés à d’autres clients présents ou aux biens du restaurant. Il sera seul responsable de toutes pertes, casses et dégradations qui pourraient être causées par ses convives sur les biens et le matériel appartenant au restaurant ou ceux mis à disposition du client à titre de location.</p>
      <p>Le restaurant décline toute responsabilité en cas de dégradation ou vol de valeurs, sacs à main, porte-documents, vêtements… appartenant aux convives ou de tout matériel entreposé dans ses salons.</p>

      <h2>Modalité de règlement</h2>
      <p>Dans certains cas convenus à l’avance avec le client dans le devis ou la proposition commerciale, la facture sera remise le jour même de la prestation et devra être réglée sur place à la fin de celle-ci.</p>
      <p>En tout état de cause, la facturation de la prestation sera faite au plus tard en fin de mois par la comptabilité et devra être réglée à réception de facture.</p>
      <p>Toute somme non payée à la date d’exigibilité mentionnée sur la facture produira de plein droit et sans mise en demeure préalable des pénalités de retard au taux égal à une fois et demie le taux d’intérêt légal.</p>
      <p>Toute contestation concernant la facturation ne pourra être prise en compte que dans les huit jours suivant la date d’envoi de la facture.</p>

      <h2>Attribution de compétence</h2>
      <p>A défaut de règlement amiable de tout litige ou contestation concernant l’interprétation ou l’application des présentes conditions générales, seul sera compétent le tribunal de commerce de Pau.</p>
    </div>
  );
}

export default CGV;