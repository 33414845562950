import { Routes, Route } from 'react-router-dom';
import '../style/index.css';
import Body from '../components/Body';
import CGV from '../components/CGV';
import Mentions from '../components/MentionsLegales';
import Page404 from '../components/page404';

function RoutesReact() {
  return (
      <Routes>
          <Route path='/' element={<Body />} />
          <Route path='/CGV' element={<CGV />} />
          <Route path='/MentionsLegales' element={<Mentions />} />
          <Route path='/*' element={<Page404 />} />
      </Routes>
  );
}

export default RoutesReact;