import "../style/index.css";
import React from "react";
import { useState } from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import * as yup from 'yup';
import emailjs from "@emailjs/browser";
import reservation from "../images/reservation.jpg";
import Modal from "./ModalReservation";
import ReCAPTCHA from "react-google-recaptcha";

function Reservation() {

  const [captchaIsDone, setCaptachIsDone] = useState(false);
  const onChange = ()  => {
    setCaptachIsDone(true);
  }

  const schema = yup.object({
    name: yup
      .string()
      .max(50)
      .required('Merci de rentrer votre nom'),
    name2: yup
      .string()
      .max(50)
      .required('Merci de rentrer votre prénom'),
    number: yup
      .number()
      .required("Merci de sélectionner le nombre d'invité"),
      phone: yup
      .number()
      .required('Merci de rentrer votre numéro de téléphone'),
      date: yup
      .date()
      .required('Merci de choisir la date'),
      time: yup
      .string()
      .required('Merci de rentrer une heure'),
      email: yup
        .string()
        .email('Merci de renter une adresse mail valide')
        .max(255)
        .required('Merci de rentrer votre adresse mail'),
    message: yup
      .string(),
  })
  .required();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [isFormValid, setIsFormValid] = useState(false); // État pour vérifier la validité du formulaire

  const onSubmit = async (data, r) => {
    try {
        // Formate la date au format "15-10-2023"
      const formattedDate = format(new Date(data.date), 'dd-MM-yyyy');
      // alert('Votre demande de réservation est prise en compte. Le restaurant Le Louisabella va prendre contact avec vous pour confirmer.');
        // const templateId = 'template_miy3l2i';
        // const serviceId = 'service_fac73yj';
        const templateId = 'template_0godlpo';
        const serviceId = 'service_4u8tt8v';
        sendFeedback(serviceId, templateId, {
          name: data.name,
          name2: data.name2,
          number: data.number,
          phone: data.phone,
          date: formattedDate, // Utilisez la date formatée ici
          time: data.time,
          email: data.email,
          message: data.message,
          reply_to: r.target.reset(),
        });
        // Si le formulaire est valide, ouvrir la modale
        if (Object.keys(errors).length === 0) {
          setIsFormValid(true);
        }
    } catch (error) {
      // Ne pas afficher le modal en cas d'erreur
      console.error(error);
    }
  };

  const sendFeedback = (serviceId, templateId, variables) => {
    return new Promise((resolve, reject) => {
      emailjs
        // .send(serviceId, templateId, variables, 'i2OpHzTDYGctCHuah')
        .send(serviceId, templateId, variables, 'KPxfL5yBfhXQwQ3Tn')
        .then((res) => {
          console.log('succes');
          resolve(res);
        })
        .catch((err) => {
          console.error('Il y a une erreur', err);
          reject(err);
        });
    });
  };

  return (
    <section id="reservation">
      <div className="reservationImage">
        <img src={reservation} alt="réservation" />
      </div>
      <div className="reservationGoogle">
        <h2>Réservation</h2>
        <form onSubmit={handleSubmit(onSubmit)} action="#">
          <div className="dbl-field">
            <div className="formulaire">
              <p>Entrez votre nom :</p>
              <input type="text" name="name" id="name" {...register('name')} />
            </div>
            {errors.name && <p id="c-yup">{errors.name.message}</p>}
            <div className="formulaire">
              <p>Entrez votre prénom :</p>
              <input type="text" name="name2" id="name" {...register('name2')} />
            </div>
            {errors.name2 && <p id="c-yup">{errors.name2.message}</p>}
            <div className="formulaire">
              <p>Entrez le nombre de personnes :</p>
              <input type="number" name="number" id="number" min="0" {...register('number')}/>
            </div>
            {errors.number && <p id="c-yup">{errors.number.message}</p>}
            <div className="formulaire2">
              <div className="formulaire">
                <p>Entrez la date :</p>
                <input type="date" name="date" id="date" {...register('date')} />
              </div>
              {errors.date && <p id="c-yup">{errors.date.message}</p>}
              <div className="formulaire">
                <p>Entrez l'heure :</p>
                <input type="text" name="time" id="time" {...register('time')} />
              </div>
              {errors.time && <p id="c-yup">{errors.time.message}</p>}
            </div>
            <div className="formulaire">
              <p>Entrez le numéro de téléphone :</p>
              <input
                type="text"
                name="phone"
                id="phone"
                {...register('phone')}
              />
            </div>
            {errors.phone && <p id="c-yup">{errors.phone.message}</p>}
            <div className="formulaire">
              <p>Entrez votre mail :</p>
              <input type="mail" name="email" id="email" {...register('email')} />
            </div>
            {errors.email && <p id="c-yup">{errors.email.message}</p>}
            <div className="formulaire message">
              <p>Entrez votre message :</p>
              <textarea name="message" id="message" {...register('message')}></textarea>
            </div>
            <ReCAPTCHA sitekey="6LdYiCwpAAAAAEti8QeQ5lcd8U2HNFHjkbMNftNk" onChange={onChange} />
            <Modal isFormValid={isFormValid} setIsFormValid={setIsFormValid} />
            {captchaIsDone && <button type="submit">Envoyer</button>}
          </div>
        </form>
      </div>
    </section>
  );
}

export default Reservation;
