import '../style/index.css';
import img11 from '../images/img11.jpg';
import img21 from '../images/img21.jpg';
import img31 from '../images/img31.jpg';
import img41 from '../images/img41.jpg';
import img51 from '../images/img51.jpg';
import img72 from '../images/img72.jpg';
import img61 from '../images/img61.jpg';
import img91 from '../images/img91.jpg';

function Cuisine() {
    return (
        <section id="cuisine">
            <div className="cuisinePhoto">
                <img src={img11} alt="plat" />
                <img src={img21} alt="plat" />
                <img src={img51} alt="dessert" />
                <img src={img61} alt="cocktail" className='hiddenCuisine'/>
            </div>
            <div className='cuisineText'>
                <h2>Notre Cuisine</h2>
                <p>Bienvenue dans l'univers gourmand de notre brasserie-restaurant LE LOUISABELLA, où chaque plat raconte une histoire de saveurs authentiques. À la croisée du traditionnel et du contemporain, notre cuisine se distingue par sa simplicité élégante et son raffinement.</p>
                <h3>Des Saveurs Authentiques</h3>
                <p> Nous croyons en la puissance des ingrédients de qualité, issus de préférence de producteurs locaux soigneusement sélectionnés. Chaque plat est une célébration des saveurs naturelles, préparée avec passion et dévouement par notre talentueuse équipe.</p>
                <h3>La Magie du Fait Maison</h3>
                <p>Chez nous, le "fait maison" n'est pas simplement une mention, c'est notre philosophie culinaire. De la préparation de nos sauces maison à la confection quotidienne de nos plats, chaque détail est pensé pour offrir une expérience gastronomique authentique.</p>
                <h3>Cuisine Simple, Travail Méticuleux</h3>
                <p> Nous apprécions la simplicité, mais nous ne faisons aucun compromis sur la qualité. Notre équipe talentueuse s'attelle à transformer des ingrédients simples en plats extraordinaires. Chaque assiette est une fusion de techniques culinaires expertes et de créativité débordante.</p>
                <h3>Un Menu Évolutif</h3>
                <p>Pour satisfaire les palais les plus exigeants, notre menu évolue au fil des saisons. Nous sommes constamment à la recherche de nouvelles inspirations pour vous offrir une expérience gastronomique toujours renouvelée, mettant en valeur les produits les plus frais et les combinaisons de saveurs les plus audacieuses.</p>
                <h3>Un Lieu de Partage et de Convivialité</h3>
                <p>La convivialité est au cœur de notre établissement. Notre ambiance chaleureuse et notre personnel attentif créent un cadre où les repas deviennent des moments de partage et de joie. Que ce soit pour un dîner décontracté entre amis, une célébration spéciale en famille ou un rendez-vous romantique, LE LOUISABELLA est l'endroit idéal pour créer des souvenirs mémorables autour de délices culinaires.</p>
            </div>
            <div className="cuisinePhoto">
                <img src={img31} alt="plat" />
                <img src={img41} alt="plat" />
                <img src={img72} alt="dessert" />
                <img src={img91} alt="cocktail" className='hiddenCuisine'/>
            </div>
        </section>
    )
}

export default Cuisine;