import '../style/index.css';

function Restaurant() {
    const restaurantGuruHtml = `
        <link href="https://awards.infcdn.net/2024/r_rcm.css" rel="stylesheet"/>
        <div id="b-rcircle" data-length="28" class=" b-rcircle_black f13 " onclick="if(event.target.nodeName.toLowerCase() != 'a') {window.open(this.querySelector('.b-rcircle_r-link').href);return 0;}">
        <a href="https://restaurantguru.com/Le-louisabella-Biron-Aquitaine" class="b-rcircle_r-link" target="_blank">LE LOUISABELLA - RESTAURANT DU LAC DE BIRON ORTHEZ</a>
        <p class="b-rcircle_year">2024</p>
        <div class="b-rcircle_bottom ">
        <p class="b-rcircle_str1 ">Recommandé</p>
        </div>
        <div class="b-rcircle_heading">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="144px" height="144px" viewBox="0 0 144 144">
        <defs>
        <path id="b-rcircle-arc" d="M 12 72 a 60 60 0 0 0 120 0"></path>
        </defs>
        <text class="b-rcircle_heading__bottom " fill="#fff" text-anchor="middle">
        <textPath startOffset="50%" xlink:href="#b-rcircle-arc"><a href="https://restaurantguru.com/" target="_blank" class="b-rcircle_heading__link">Restaurant Guru</a></textPath>
        </text>
        </svg>
        </div></div>`;
    return (
        <section id="restaurant">
            <div className='divResto'>
                <div className="certification">
                    <div dangerouslySetInnerHTML={{ __html: restaurantGuruHtml }} className='back-certif' />
                </div>
                <div>
                    <h1>Le Louisabella</h1>
                    <p>Installé au Lac de BIRON ORTHEZ dans le département des Pyrénées Atlantiques, le Restaurant Le Louisabella est vraiment l’endroit idéal pour échapper à la routine et profiter de la nature tout en dégustant les saveurs du coin.</p>
                    <p>Vous dégusterez des plats du chef dans un endroit calme et verdoyant, vous offrant la possibilité après un bon repas d'aller participer à des activités nautiques, de vous divertir à la zone de jeux pour enfants, de vous prélasser à la plage ou de vous promener le long des berges du lac de BIRON ORTHEZ.</p>
                    <p>Le Restaurant Le Louisabella met à votre disposition une avalanche de produits et services : Crêperie, glacier, bar et plus encore !</p>
                    <p>En famille ou entre amis, l’ambiance y est toujours conviviale ! Vous serez reçus dans les meilleures conditions pour passer un bon moment !</p>
                </div>
            </div>
        </section>
    )
}

export default Restaurant;