import React, { useState } from "react";
import "../style/index.css";
import Burger from "../images/burger.jpg";

function Header() {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <header>
        {/* Menu burger pour les petits écrans */}
        <div className="mobile-menu">
            {/* Toujours afficher l'image */}
            <img
                src={Burger}
                alt="menu burger"
                className="burger-icon"
                onClick={toggleMenu}
            />
            {showMenu && (
                // Afficher le menu si showMenu est vrai
                <nav className="menu-items">
                    <ul>
                        <li><a href="#accueil">Accueil</a></li>
                        <li><a href="#restaurant">Le Restaurant</a></li>
                        <li><a href="#menu">Au Menu</a></li>
                        <li><a href="#cuisine">Notre cuisine</a></li>
                        <li><a href="#reservation">Réservation</a></li>
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                </nav>
            )}
        </div>
        {/* Barre de navigation normale pour les écrans plus larges */}
        <nav className="desktop-menu">
          <ul>
            <li><a href="#accueil">Accueil</a></li>
            <li><a href="#restaurant">Le Restaurant</a></li>
            <li><a href="#menu">Au Menu</a></li>
            <li><a href="#cuisine">Notre cuisine</a></li>
            <li><a href="#reservation">Réservation</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
    </header>
  );
}

export default Header;
