import React from "react";
import "../style/index.css";
import { Link } from "react-router-dom";
import sirene2 from "../images/sirene2.jpg";

function Page404() {
  return (
    <div className="page404">
      <img src={sirene2} alt="sirene" />
      <h1>Page 404</h1>
      <h4>Une erreur vient de se produire...</h4>
      <h4>Veuillez retourner à l'accueil.</h4>
      <div>
        <button className="buttonAccueil">
          <Link to="/">
            <p>Retour à la page d'accueil</p>
          </Link>
        </button>
      </div>
    </div>
  );
}

export default Page404;
