import '../style/index.css';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer>
            <p>@Le Louisabella 2023</p>
            <div>
                <Link to="/CGV"><p>CGV</p></Link>
                <Link to="/MentionsLegales"><p>Mentions légales</p></Link>
            </div>
        </footer>
    )
}

export default Footer;