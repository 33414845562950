import React from "react";
import "../style/index.css";
import { Link } from "react-router-dom";

function Mentions() {
  return (
    <div className="conditions">
      <div>
        <button className="buttonAccueil">
          <Link to="/">
            <p>Retour à la page d'accueil</p>
          </Link>
        </button>
      </div>
      
      <h1>Mentions légales</h1>
      <section>
        <p>
            Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour
            la confiance en l’économie numérique, il est précisé aux utilisateurs du
            site lelouisabella.fr l’identité des différents intervenants dans le
            cadre de sa réalisation et de son suivi.
        </p>
      </section>

      <section>
        <h2>Edition du site</h2>
        <p>Le site lelouisabella.fr est édité par la société Louisabella, </p>
        <p>SARL dont le siège social est situé :</p>
        <p>
            Avenue Marcel Paul - Base de Loisirs du Lac de BIRON ORTHEZ - 64300
            ORTHEZ France,{" "}
        </p>
        <p>
            immatriculée au registre du commerce et des sociétés sous le numéro
            d’identification unique N° RCS : Pau B 949 900 781.
        </p>
      </section>

      <section>
        <h2>Responsable de publication</h2>
        <p>M. Franck NAVAILLES</p>
      </section>

      <section>
        <h2>Hébergeur</h2>
        <p>Le site lelouisabella.fr est hébergé par la société OVH</p>
        <p>Adresse: 2 rue Kellermann - BP 80157 59053 ROUBAIX CEDEX FRANCE</p>
      </section>

      <section className="credits">
        <h3>Crédits</h3>
        <p>Crédits images: @wirestock - Freepik.com , @Freepik - Freepik.com , @izikmd - Adobe Stock</p>
        <p>Crédits : @jayle</p>
      </section>
    </div>
  );
}

export default Mentions;
