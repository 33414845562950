import "../style/index.css";
import "leaflet/dist/leaflet.css";
import { MapContainer } from "react-leaflet";
import { TileLayer } from "react-leaflet";
import {Marker} from "react-leaflet";
import {Icon} from "leaflet";

function Map() {
    const customIcon = new Icon({
        iconUrl: require("../images/mapPin.png"),
        iconSize: [40, 40]
    })
  return (
    <MapContainer center={[43.47373201992026, -0.7566088883516807]} zoom={15} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[43.4735, -0.75665]} icon={customIcon}>
        {/* <Popup>
          Le Louisabella
        </Popup> */}
      </Marker>
    </MapContainer>
  );
}

export default Map;
