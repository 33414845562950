import React from "react";
// import useState{ useState, useEffect } from "react";
//import moment from "moment";
import "../style/index.css";
import sirene from "../images/sirene.jpg";
// import menu from '../PDF/menu1.pdf';
//import menuData from "../json/menus.json";
//import promotionData from "../json/promotion.json";

function Menu() {
    //const [menuIndex, setMenuIndex] = useState(0);
    //const [dateDuJour, setDateDuJour] = useState('');

    // useEffect(() => {
    //   const dateActuelle = new Date();
    //   // const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    //   const options2 = { year: 'numeric', month: 'long', day: 'numeric' };
    //   const dateFormatee = dateActuelle.toLocaleDateString('fr-FR', options2);
  
    //   setDateDuJour(dateFormatee);
    // }, []);

    // useEffect(() => {
    //   const dateActuelle = new Date();
    //   const jour = String(dateActuelle.getDate()).padStart(2, '0');
    //   const mois = String(dateActuelle.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
    //   const annee = dateActuelle.getFullYear();
      
    //   const dateFormatee = `${jour}/${mois}/${annee}`;
    //   setDateDuJour(dateFormatee);
    // }, []);

    // useEffect(() => {
    //   const startTime = moment('30-10-2023 00:03', 'DD-MM-YYYY HH:mm');
  
    //   const updateMenuIndex = () => {
    //     const elapsedTime = moment().diff(startTime, 'day');
    //     const newIndex = Math.floor(elapsedTime) % menuData.length;
    //     setMenuIndex(newIndex);
    //   };
  
    //   updateMenuIndex();
      
    //   // 24h en millisecondes: 24 * 60 * 60 * 1000
    //   const intervalId = setInterval(updateMenuIndex, (24 * 60 * 60) * 1000);
  
    //   // Nettoyez l'intervalle lorsque le composant est démonté
    //   return () => clearInterval(intervalId);
    // }, []);
  
    //const currentMenu = menuData[menuIndex];
    // console.log(currentMenu);

    // Trouver la promotion correspondant à la date actuelle
  //const promotionForToday = promotionData.find(promo => promo.jourmoisan === dateDuJour);
  

  return (
    <section id="menu">
      <div className="menuPhoto">
        <img src={sirene} alt="Sirene restaurant" />
      </div>
      <div className="menus">
        <h2>Au menu aujourd'hui :</h2>
        <div className="choixMenu">
          {/* <div className="menuPart">
            <h3>Le midi</h3> */}
            {/* <button className="menuButton"><a href={menu}>Le Menu</a></button> */}            
            {/* <a href={currentMenu.menu} className="lienButton"><button className="menuButton">Le Menu</button></a>*/}
            {/* {promotionForToday ? (
              <a href={promotionForToday.menu} className="lienButton">
                <button className="menuButton">Menu à 18€</button>
              </a>
            ) : (
              <a href={currentMenu.menu} className="lienButton">
                <button className="menuButton">Menu à 18€</button>
              </a>
            )} */}
          {/* </div>
          <div className="menuPart">
            <h3>Le soir</h3>            
            <a href="../PDF/carte.pdf"><button className="menuButton">La Carte</button></a>            
          </div> */}
          <div className="menuPart2">
            <a href="../PDF/carte2.pdf"><button className="menuButton longButton">La Carte</button></a>            
          </div>
        </div>
        {/* <div className="menuPart">             
          <a href="../PDF/valentin.pdf"><button className="menuButton miseEnAvant">Menu Saint Valentin</button></a>            
        </div> */}
      </div>
    </section>
  );
}

export default Menu;
