import React from 'react';
import {useState, useEffect} from 'react';
import "../style/modal.css";

function ModalReservation({ isFormValid, setIsFormValid }) {

  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (isFormValid) {
      setModal(true);
      setIsFormValid(false); // Réinitialiser l'état pour une prochaine soumission
    }
  }, [isFormValid, setIsFormValid]);

  const toggleModal = () => {
    setModal(!modal)
  }

  return (
    <>
      {modal && (
        <div className='modal'>
          <div className='overlay' onClick={toggleModal} ></div>
          <div className='modal-content'>
            <h2>Merci</h2>
            <p>Votre demande de réservation est prise en compte. Le restaurant Le Louisabella va prendre contact avec vous pour confirmer.</p>
            <button className='close' onClick={toggleModal} >Fermer</button>
          </div>
        </div>
      )}
    </>
  );
}

export default ModalReservation;
